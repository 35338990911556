import React, { useState, useEffect } from "react"
import { validateEmail, formatDate } from "../../../utils/functions"
import { Loader } from "../../Shared/Loader"
import styles from "./../../../scss/components/Fanfest/_StepForm.module.scss"
import { useHistory } from "react-router"
import Cookies from "js-cookie"

const CheckboxInput = ({ name, label, isChecked, setIsChecked, isMobile }) => {
  const toggleCheck = e => {
    setIsChecked(() => !isChecked)
  }

  return (
    <div>
      <input
        type="checkbox"
        className={styles.termsRadio}
        name={name}
        id={name}
        checked={isChecked}
        onChange={toggleCheck}
      />
      <label htmlFor={name}>{/* <span>{label}</span> */}</label>
    </div>
  )
}

export default function Step4({
  propValues,
  setValues,
  error,
  setError,
  selectedProvince,
  isSafari,
  isMobile,
  selectedEvent,
  setStep,
  handleChange,
}) {
  const [isChecked, setIsChecked] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  let history = useHistory()

  const preferenceOptions = [
    {
      id: "1",
      value: "Cocinando/comiendo solo o acompañado",
    },
    {
      id: "2",
      value:
        "Cuando me quiero relajar, escuchando música o usando RRSS",
    },
    {
      id: "3",
      value: "Con amigxs en un bar o en una casa",
    },

    {
      id: "4",
      value: "Antes o durante una fiesta o evento",
    },
    {
      id: "5",
      value: "Trasladándome a algún lugar o mientras estoy en mi casa",
    },
    {
      id: "6",
      value: "No tomo cerveza",
    },
  ]

  useEffect(() => {
    if (error && propValues.instagram && propValues.dateofbirth) {
      setError("")
    }
  }, [error, propValues])

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    const td = Cookies.get("_td")
    console.log("submiit", {
      ...propValues,
      event: selectedEvent.node.name,
      pdv: selectedEvent.node.address,
      td: td,
    })
    if (propValues.instagram && propValues.dateofbirth) {
      isChecked
        ? fetch("https://wabudweiser-prd.azurewebsites.net/fanfest", {
            // ? fetch("http://localhost:8080/fanfest", {
            method: "POST",
            body: JSON.stringify({
              ...propValues,
              event: selectedEvent.node.name,
              pdv: selectedEvent.node.address,
              td: td,
            }),
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }).then(response => {
            if (response.ok) {
              setSubmitted(false)
              history.push("/fanfest/success")
            } else {
              response.text().then(text => {
                console.error("Error guardando los datos. \n", text)
              })
            }
          })
        : alert("Debes aceptar los terminos y condiciones para continuar") &&
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "Form",
            event_action: "Error",
            event_label: "Falta aceptar terminos y condiciones",
            interaction: true,
            component_name: "boton_enviar",
          })
    } else {
      setError("Debe completar todos los campos")
    }
  }

  return !submitted ? (
    <form className={styles.formContainer2} onSubmit={handleSubmit}>
      <div className={styles.fieldContainerStep2}>
        <input
          className={styles.inputStyle}
          name="dateofbirth"
          placeholder="Fecha de nacimiento"
          value={propValues.dateofbirth}
          onChange={({ target }) => {
            const onlyNums = target.value.replace(/[^0-9]/g, "")
            const date = formatDate(onlyNums)
            setValues({ ...propValues, dateofbirth: date })
          }}
        />
        <input
          className={styles.inputStyle}
          name="instagram"
          placeholder="Instagram"
          value={propValues.instagram}
          onChange={handleChange}
        />
      </div>

      <h1 className={styles.titleText4}>¿Cuando tomás cerveza usualmente?</h1>
      {/* <input
          className={styles.inputStyle2}
          name="preferenceQuestion"
          placeholder="Escriba aquí"
          onChange={handleChange}
          value={propValues.preferenceQuestion}
        /> */}
      <select
        className={styles.inputStyle}
        name="preferenceQuestion"
        value={propValues.preferenceQuestion}
        onChange={handleChange}
      >
        <option selected hidden>
          Selecciona opción
        </option>
        {preferenceOptions.map(option => {
          return (
            <option key={option.id} value={option.value}>
              {option.value}
            </option>
          )
        })}
      </select>

      {/* TERMS START */}
      <div
        className={
          isSafari && !isMobile
            ? styles.btnContainerSendSafari
            : styles.btnContainerSend
        }
      >
        <div className={styles.termsContainer}>
          <CheckboxInput
            name="remember-me"
            label="Remember Me"
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
          <h4 className={styles.termsText}>
            He leído y acepto&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/BBYCC LA MUSICA EN TUS MANOS - Sorteos BUD.docx"
            >
              Bases y Condiciones
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/PoliticadePrivacidad.pdf"
            >
              Políticas de Privacidad
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/Terminos-Condiciones.pdf"
            >
              Términos y condiciones
            </a>
            &nbsp;y activaciones de marketing.
          </h4>
        </div>
        {/* TERMS END */}

        <button className={styles.btnSend} type="submit" disabled={submitted}>
          Enviar
        </button>

        {error ? (
          <div style={{ color: "red", marginTop: "1vh", textAlign: "center" }}>
            {error}
          </div>
        ) : null}
      </div>
    </form>
  ) : (
    <Loader />
  )
}
