import React, { useState, useEffect } from "react"
import provincias from "./provincias.json"
import localidades from "./proviciasylocalidades.json"
import styles from "./../../../scss/components/Fanfest/_StepForm.module.scss"

export default function Step1({
  setPage,
  setValues,
  propValues,
  isSafari,
  isMobile,
  setStep,
  handleChange,
  error,
  setError,
}) {
  const [cities, setCities] = useState([])

  useEffect(() => {
    const localidadesArr = Object.entries(localidades)
    if (propValues.state) {
      const filter = localidadesArr.filter(([key, value]) => {
        return key === propValues.state
      })
      const justStrings = Object.fromEntries(filter)
      setCities(justStrings[propValues.state])
    }
  }, [propValues.state])

  useEffect(() => {
    if (
      propValues.fullname &&
      propValues.email &&
      propValues.state &&
      propValues.city
    ) {
      setError("")
    }
  }, [propValues, error])

  const onSubmit = e => {
    e.preventDefault()
    if (
      propValues.fullname &&
      propValues.email &&
      propValues.state &&
      propValues.city
    ) {
      setPage(2)
    } else {
      setError("Debe completar todos los campos")
    }
  }

  return (
    <form className={styles.formContainer} onSubmit={onSubmit}>
      <div className={styles.fieldContainer}>
        <input
          className={styles.inputStyle}
          name="fullname"
          onChange={handleChange}
          value={propValues.fullname}
          placeholder="Nombre y apellido"
        />

        <input
          className={styles.inputStyle}
          name="email"
          type="email"
          value={propValues.email}
          onChange={handleChange}
          placeholder="Mail"
        />

        <select
          className={styles.inputStyle}
          name="state"
          value={propValues.state}
          placeholder="Provincia"
          onChange={handleChange}
        >
          <option selected hidden>
            Selecciona provincia
          </option>
          {provincias
            .sort((a, b) => {
              const n = a.value.localeCompare(b.value)
              return n == 0 && a.value !== n.value
                ? b.value.localeCompare(a)
                : n
            })
            .map(option => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
        </select>
        <select
          className={styles.inputStyle}
          name="city"
          value={propValues.city}
          onChange={handleChange}
          placeholder="Localidad"
        >
          <option selected hidden>
            Selecciona localidad
          </option>
          {cities
            .sort((a, b) => {
              const n = a.localeCompare(b)
              return n == 0 && a !== n ? b.localeCompare(a) : n
            })
            .map(option => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })}
        </select>

        <div
          className={
            isSafari && !isMobile
              ? styles.btnContainerSafari
              : styles.btnContainer
          }
          style={{ marginTop: "1vh" }}
        >
          <button className={styles.btnSubmit} type="submit">
            Siguiente
          </button>
        </div>
        {error ? (
          <div style={{ color: "red", marginTop: "1vh", textAlign: "center" }}>
            {error}
          </div>
        ) : null}
      </div>
    </form>
  )
}
