import React, { useState } from "react"
import styles from "../../scss/components/Fanfest/_Map.module.scss"
import Logo2 from "../../images/icons/logoBud2.svg"
import products from "../../images/Fanfest/budProducts.png"
import BackIcon from "../../images/BackToLive/backIcon.png"
import Map from "./Map"
import ReactTooltip from "react-tooltip"
import Event from "./Event"
import StepForm from "./StepForm"
import useDeviceDetect from "./hooks/useDevice"
import eventData from "./utils/events.json"

export default function MapSection({ uruguay, provincesEvents, setStep }) {
  const events = provincesEvents
  const [content, setContent] = useState("")
  const [isProvince, setIsProvince] = useState(uruguay ? true : false)
  const [isArtistSelected, setIsArtistSelected] = useState(false)
  const [selectedProvince, setSelectedProvince] = useState(
    uruguay ? { NAME: "Punta del Este", URL: "Uruguay.svg" } : ""
  )
  const [isForm, setIsForm] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState()
  const { isMobile, isSafari } = useDeviceDetect()
  function handleBack() {
    setIsArtistSelected(false)
    setIsProvince(false)
  }
  function handleToForm() {
    setIsForm(true)
  }

  const isArtistDisplayInMobile = isMobile && !isProvince && isArtistSelected
  return !uruguay ? (
    <div className={styles.container}>
      {isMobile ? (
        <div className={styles.wrapper}>
          {isArtistDisplayInMobile ? null : (
            <div className={styles.firstColumn}>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "space-between",
                  marginTop: "10vh",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h1 className={styles.firstText}>
                    FAN FEST FIFA X BUDWEISER{" "}
                  </h1>
                  {!isProvince ? (
                    <h2 className={styles.secondText}>
                      ¡Encontralos en el mapa!
                    </h2>
                  ) : (
                    <>
                      <h2 className={styles.provinceText}>
                        {selectedProvince.NAME}
                      </h2>
                      <h2 className={styles.selectPlaceText}>
                        Seleccioná el lugar
                      </h2>
                    </>
                  )}
                </div>

                <img
                  src={products}
                  className={
                    !isForm ? styles.logoBudMobile : styles.logoBudMobile2
                  }
                />
              </div>
            </div>
          )}
          {isArtistDisplayInMobile ? null : (
            <div className={styles.secondColumn}>
              <Map
                eventos={events}
                setTooltipContent={setContent}
                isProvince={isProvince}
                setIsProvince={setIsProvince}
                selectedProvince={selectedProvince}
                setSelectedProvince={setSelectedProvince}
                setIsArtistSelected={setIsArtistSelected}
                isMobile={isMobile}
                isSafari={isSafari}
                eventData={eventData}
                setSelectedEvent={setSelectedEvent}
              />
              <ReactTooltip>{content}</ReactTooltip>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.firstColumn}>
            <h1 className={styles.firstText}>FAN FEST FIFA X BUDWEISER</h1>
            {!isProvince ? (
              <h2 className={styles.secondText}>¡Encontralos en el mapa!</h2>
            ) : (
              <>
                <h2 className={styles.provinceText}>{selectedProvince.NAME}</h2>
                <h2 className={styles.selectPlaceText}>Seleccioná el lugar</h2>
              </>
            )}
          </div>

          <div className={styles.secondColumn}>
            <Map
              eventos={events}
              setTooltipContent={setContent}
              isProvince={isProvince}
              setIsProvince={setIsProvince}
              selectedProvince={selectedProvince}
              setSelectedProvince={setSelectedProvince}
              setIsArtistSelected={setIsArtistSelected}
              isMobile={isMobile}
              isSafari={isSafari}
              eventData={eventData}
              setSelectedEvent={setSelectedEvent}
            />
            <ReactTooltip>{content}</ReactTooltip>
          </div>
          <div className={!isForm ? styles.thirdColumn : styles.thirdColumn2}>
            {!isForm ? (
              <img
                src={products}
                className={!isForm ? styles.logoBud : styles.logoBud2}
              />
            ) : (
              <img
                src={Logo2}
                className={!isForm ? styles.logoBud : styles.logoBud2}
              />
            )}
          </div>
        </div>
      )}

      {isProvince && !isForm ? (
        <div className={!isMobile ? styles.backBtn : styles.backBtnMobile}>
          <a className={styles.btnWrapper} onClick={handleBack}>
            <img src={BackIcon} style={{ objectFit: "contain" }} />
            <h3>Volver</h3>
          </a>
        </div>
      ) : null}

      {isArtistSelected ? (
        <>
          <div className={styles.eventContainer}>
            <Event
              selectedEvent={selectedEvent}
              isForm={isForm}
              handleToForm={handleToForm}
            />
            {isMobile ? (
              <div
                style={{ display: isForm ? "none" : "show" }}
                className={styles.backBtnMobile2}
              >
                <a className={styles.btnWrapper2} onClick={handleBack}>
                  <img src={BackIcon} style={{ objectFit: "contain" }} />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {isForm ? (
        <StepForm
          selectedEvent={selectedEvent}
          selectedProvince={selectedProvince}
          setStep={setStep}
        />
      ) : null}
    </div>
  ) : (
    //SOLO PARA URUGUAY
    <div className={styles.container}>
      {isMobile ? (
        <div className={styles.wrapper}>
          <img
            src={Logo2}
            className={!isForm ? styles.logoBudMobile : styles.logoBudMobile2}
          />
          {isArtistDisplayInMobile ? null : (
            <div className={styles.firstColumn}>
              <h1 className={styles.firstText}>
                Descubrí los eventos de Uruguay
              </h1>
              {!isProvince ? (
                <h2 className={styles.secondText}>
                  ¡Tocando en <br /> el mapa!
                </h2>
              ) : (
                <>
                  <h2 className={styles.provinceText}>
                    {selectedProvince.NAME}
                  </h2>
                  <h2 className={styles.selectPlaceText}>
                    Seleccioná el lugar
                  </h2>
                </>
              )}
            </div>
          )}

          {isArtistDisplayInMobile ? null : (
            <div className={styles.secondColumn}>
              <Map
                eventos={events}
                setTooltipContent={setContent}
                isProvince={isProvince}
                setIsProvince={setIsProvince}
                selectedProvince={selectedProvince}
                setSelectedProvince={setSelectedProvince}
                setIsArtistSelected={setIsArtistSelected}
                isMobile={isMobile}
                isSafari={isSafari}
                eventData={eventData}
                setSelectedEvent={setSelectedEvent}
              />
              <ReactTooltip>{content}</ReactTooltip>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.firstColumn}>
            <h1 className={styles.firstText}>FAN FEST FIFA X BUDWEISER</h1>
            {!isProvince ? (
              <h2 className={styles.secondText}>¡Encontralos en el mapa!</h2>
            ) : (
              <>
                <h2 className={styles.provinceText}>{selectedProvince.NAME}</h2>
                <h2 className={styles.selectPlaceText}>Seleccioná el lugar</h2>
              </>
            )}
          </div>

          <div className={styles.secondColumn}>
            <Map
              eventos={events}
              setTooltipContent={setContent}
              isProvince={isProvince}
              setIsProvince={setIsProvince}
              selectedProvince={selectedProvince}
              setSelectedProvince={setSelectedProvince}
              setIsArtistSelected={setIsArtistSelected}
              isMobile={isMobile}
              isSafari={isSafari}
              eventData={eventData}
              setSelectedEvent={setSelectedEvent}
            />
            <ReactTooltip>{content}</ReactTooltip>
          </div>
          <div
            className={
              isArtistSelected ? styles.thirdColumn2 : styles.thirdColumn
            }
          >
            <img
              src={Logo2}
              className={!isForm ? styles.logoBud : styles.logoBud2}
            />
          </div>
        </div>
      )}
      {isArtistSelected ? (
        <>
          <div className={styles.eventContainer}>
            <Event
              selectedEvent={selectedEvent}
              isForm={isForm}
              handleToForm={handleToForm}
            />
            {isMobile ? (
              <div
                style={{ display: isForm ? "none" : "show" }}
                className={styles.backBtnMobile2}
              >
                <a className={styles.btnWrapper2} onClick={handleBack}>
                  <img src={BackIcon} style={{ objectFit: "contain" }} />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {isForm ? (
        <StepForm
          selectedEvent={selectedEvent}
          selectedProvince={selectedProvince}
          setStep={setStep}
        />
      ) : null}
    </div>
  )
}
