import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { graphql } from "gatsby"
import { BrowserRouter as Router, Route } from "react-router-dom"
import SEO from "../components/seo"
import arrowDown from "./../images/icons/arrowDown.png"
import Footer from "../components/Footer"
import Main from "../components/Fanfest/Main"
import styles from "./../scss/pages/_fanfest.module.scss"
import Success from "../components/Fanfest/Success"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import MapSection from "../components/Fanfest/MapSection"
import Backtoliveuy from "../components/Fanfest/uy/uy"

export default function Thebirrabehindthemusic({ location, data }) {
  const [age, setAge] = useState(true)
  const [step, setStep] = useState("")
  const [localActive, setLocalActive] = useState(false)
  const [sessionActive, setSessionActive] = useState(false)
  const provincias = data.allFanfestEvents.edges

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Fan Fest",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/fanfest",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: step ? `step_${step}` : "",
    })
  }, [step])

  useEffect(() => {
    // console.log("ok!")
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    // console.log("Cookie",cookie)
    if (cookie || local) setAge(false)
    if (cookie) return setSessionActive(true)
    if (local) return setLocalActive(true)
  }, [])

  return localActive || sessionActive ? (
    <Router>
      <Route exact path="/fanfest">
        <div style={{ overflowX: "hidden" }}>
          <SEO title="Fan Fest" />
          <Container fluid className="m-0 p-0">
            <section>
              <Main />
            </section>
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#MapSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src={arrowDown} className={styles.ArrowDownSection} />
                </a>
              </Col>
            </Row>
            <section id="MapSection">
              <MapSection provincesEvents={provincias} setStep={setStep} />
            </section>

            <Footer />
          </Container>
        </div>
      </Route>
      <Route path="/fanfest/success">
        <Success />
      </Route>
      <Route path="/fanfest/uy">
        <Backtoliveuy />
      </Route>
    </Router>
  ) : (
    <AgeGate path={location?.pathname} />
  )
}

export const data = graphql`
  query fanfest {
    allFanfestEvents(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          index
          address
          left
          name
          province
          schedule
          top
          url
          visible
          buttonLink
          buttonTitle
        }
      }
    }
  }
`
