import React, { useEffect, useState } from "react"
import SEO from "./../seo"
import Footer from "./../Footer"
import styles from "./../../scss/pages/_fanfest.module.scss"
import texture from "../../images/TheBirraBehind/textura-fondo.svg"
import fondo from "../../images/TheBirraBehind/bud-redform-icon-ligth.svg"
import logo from "./../../images/BackToLive/BudLogo2.svg"
import { Container } from "react-bootstrap"
import { IsMobileDevice } from "../../utils"

export default function Success() {
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <div style={{ overflow: "hidden" }}>
      <SEO title="Backtolive" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <img src={texture} className={styles.texture} />
            <img src={texture} className={styles.texture2} />
            <img src={fondo} className={styles.fondo} />
            <div>
              <img src={logo} className={styles.logo} />
            </div>
            <div className={styles.container}>
              <div className={styles.wrapText}>
                <h1 className={styles.infoTextOne}>
                ¡Gracias por participar!
                </h1>
              </div>
              {!isMobile ? (
                <div>
                  <h1 className={styles.infoTextTwo}>
                  Seguinos en
                    <span>
                      {" "}
                      <a
                        href="https://www.instagram.com/budweiser_ar/"
                        alt="instagram"
                      >
                        @budweiser_ar
                      </a>
                    </span>{" "}
                    ,
                    <br />
                    PARA NO PERDERTE NINGÚN EVENTO
                    <br />
                  </h1>
                </div>
              ) : (
                <div>
                  <h1 className={styles.infoTextTwo}>
                    TE INVITAMOS A SEGUIRNOS A <br />
                    <span>
                      {" "}
                      <a
                        href="https://www.instagram.com/budweiser_ar/"
                        alt="instagram"
                      >
                        @budweiser_ar
                      </a>
                    </span>{" "}
                    <br />
                    PARA NO PERDERTE NINGÚN EVENTO
                    <br />
                  </h1>
                </div>
              )}
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
