import React from "react"
import styles from "./../../scss/components/Fanfest/_Event.module.scss"
import LocationIcon from "./../../images/BackToLive/icons/location.svg"
import ScheduleIcon from "./../../images/BackToLive/icons/time.svg"

export default function Event({ handleToForm, isForm, selectedEvent }) {
  return (
    <div
      className={styles.container}
      style={{ background: !isForm ? "black" : "none" }}
    >
      {!isForm ? (
        <>
          <div className={styles.firstWrapper}>
            <img src={selectedEvent.node.url} className={styles.artistImg} />
            <h1 className={styles.artistName}>{selectedEvent.node.name}</h1>
            <div className={styles.infoWrap}>
              <div className={styles.infoContainer}>
                <img className={styles.iconInfo} src={LocationIcon} />
                <h2 className={styles.locationText}>
                  {selectedEvent.node.address}
                </h2>
              </div>
              <div className={styles.infoContainer}>
                <img className={styles.iconInfo} src={ScheduleIcon} />
                <h2 className={styles.scheduleText}>
                  {selectedEvent.node.schedule}
                </h2>
              </div>
            </div>
          </div>
          {/* BUTTON 2*/}
          {selectedEvent.node.buttonLink && (
            <a href={selectedEvent.node.buttonLink} className={styles.button2}>
              <h2>{selectedEvent.node.buttonTitle} </h2>
            </a>
          )}
          
          {/* BUTTON */}
          <div onClick={handleToForm} className={styles.button}>
            <h2>Participá por tu sixpack de Bud</h2>
          </div>
        </>
      ) : null}
    </div>
  )
}
